import React from 'react';
import propTypes from 'prop-types';
import styles from './default-header.module.scss';
import { staticPagesAssets } from '@enums/utils-constants';
import { useTranslation } from 'next-i18next';

interface DefaultHeaderProps {
	type: string;
}

export const DefaultHeader = ({ type }: DefaultHeaderProps) => {
	const { t, i18n } = useTranslation();
	const lang = i18n.language || 'en';
	const headerAssets = staticPagesAssets[type].header;

	const handleSubmit = (data) => {
		switch (data.type) {
			case 'email': {
				window.location.href = `mailto:${data.value}?subject=${t(headerAssets.title)}`;
				break;
			}

			default:
				break;
		}
	};

	return (
		<div
			className={`${styles['default-header-wrap']} flexbox colls start ${
				styles[headerAssets.extraStyles?.header]
			}`}
			style={{
				backgroundImage: `url('${headerAssets.background.main}')`
			}}
		>
			{headerAssets.background.inner && (
				<div className={styles['default-header-inner-img']}>
					<img src={headerAssets.background.inner} />
				</div>
			)}

			{headerAssets.isBackdrop && <div className={styles.backdrop} />}

			{headerAssets.extraAsset && (
				<img
					src={headerAssets.extraAsset?.src}
					className={styles[headerAssets.extraAsset?.classes]}
				/>
			)}
			<h1 className={styles['main-header-title']}>
				<span className={`${styles[headerAssets.extraStyles?.title]} ${styles.highlighted}`}>
					{t(headerAssets.title)}
				</span>
				<br />
				<span>{t(headerAssets.subtitle)}</span>
			</h1>
			<div
				className={`${styles['main-header-subtitle']} ${
					styles[headerAssets.extraStyles?.description]
				}`}
			>
				{t(headerAssets.description)}
			</div>
			{headerAssets.extraData && (
				<div
					className={styles['extradata-wrapper']}
					onClick={() => {
						handleSubmit(headerAssets.extraData);
					}}
				>
					{t(headerAssets.extraData.label)}{' '}
					<span className="bold">{headerAssets.extraData.value}</span>
				</div>
			)}
			{headerAssets.cta && (
				<button
					type="button"
					className={`${styles['default-btn']} default-btn`}
					style={{ backgroundColor: headerAssets.cta.color }}
					onClick={() => window.open(staticPagesAssets[type].url[lang], '_blank')}
				>
					{t(headerAssets.cta.text)}
				</button>
			)}
		</div>
	);
};

DefaultHeader.propTypes = {
	type: propTypes.string.isRequired
};

export default DefaultHeader;
