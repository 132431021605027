import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

interface Props {
	children: any;
	hideHeader?: boolean;
	hideFooter?: boolean;
	headerProps?: any;
	mainLayoutStyle?: Record<string, unknown>;
	innerWebStyle?: any;
}

const Header = dynamic(async () => import('../../components/header'));
const Footer = dynamic(async () => import('../../components/footer'));

export const PageWrapper = ({
	children,
	hideHeader,
	hideFooter,
	headerProps,
	mainLayoutStyle,
	innerWebStyle
}: Props) => {
	const router = useRouter();
	const innerWebQP = Boolean(Number(router.query.innerweb));
	const darkModeQP = Boolean(Number(router.query.dark_mode));

	return (
		<div
			className={`page-wrapper ${innerWebQP && 'inner-web'} ${darkModeQP ? 'dark-mode' : ''}`}
			style={innerWebQP ? innerWebStyle : null}
		>
			{hideHeader || innerWebQP ? null : <Header headerProps={headerProps} />}
			<div className="main-layout" style={mainLayoutStyle}>
				{children}
			</div>
			{hideFooter || innerWebQP ? null : <Footer />}
		</div>
	);
};
