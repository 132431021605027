import React from 'react';
import { PageWrapper } from '@components/pagewrapper/pagewrapper';
import { DefaultHeader } from '@components/default-header/default-header';
import { environment } from '@environment';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import styles from './anghamistudios.module.scss';
import { initNeoGatewayServer } from '@helpers/neogateway';
import withAuth from '@components/auth';

export const AnghamiStudios = () => {
	const type = 'anghamistudios';

	return (
		<PageWrapper hideHeader={false} hideFooter={false}>
			<DefaultHeader type={type} />
			<div className={styles['anghami-studios-container']}>
				<div className="flexbox">
					<video controls id="anghami-studios-video-id" className={styles['anghami-studios-video']}>
						<source
							type="video/mp4"
							src={`${environment.web_cdn_url}new/anghami-studios-web/anghami-studio-showreel.mp4`}
						/>
					</video>
				</div>
			</div>
		</PageWrapper>
	);
};

export async function getServerSideProps(ctx: any) {
	const { pageProps } = await initNeoGatewayServer(ctx);
	return {
		props: {
			...pageProps,
			...(await serverSideTranslations(ctx.locale, ['common']))
		}
	};
}

export default withAuth(AnghamiStudios);
